<template>
    <div class="add-notification-issuance">
        <van-form class="form-container" @submit="onSubmit" :disabled="!isAdmin">
            <van-field
                v-model="notifyInfo.title"
                class="title-label"
                label="标题"
                required
                
            />
            <van-field
                v-model="notifyInfo.title"
                name="title"
                label=""
                class="title-input"
                autofocus
                placeholder="请输入标题"
                :rules="[{ required: true}]"
            />
            <!-- <van-field
                v-model="notifyInfo.content"
                rows="2"
                autosize
                label="内容"
                type="textarea"
                maxlength="50"
                placeholder="请输入内容"
                show-word-limit
                :rules="[{ required: true}]"
            /> -->
            <van-field
                v-model="notifyInfo.context"
                class="title-label"
                label="内容"
                required
            />
            <!-- <div class="left-label title-label">内容</div> -->
            <div class="mavon-editor" id="content">
                <mavon-editor ref="mavonEditor" :autofocus="false" :subfield="!isAdd" :editable="isAdmin"  v-model="notifyInfo.context" :toolbars="toolbars" @imgAdd="handleEditorImgAdd"  @imgDel="imgDel"/>
            </div>
            <van-field name="switch" label="是否置顶" class="is-top" v-show="isAdmin">
                <template #input>
                    <van-switch v-model="switchChecked" size="20" />
                </template>
            </van-field>
            <van-field v-model="notifyInfo.levelName" class="title-input" label="重要程度" @click="shouLevel" readonly v-show="isAdmin"/>
            <van-action-sheet
                v-model="show"
                :actions="actions"
                cancel-text="取消"
                close-on-click-action
                @cancel="onCancel"
                @select="selectLevel"
            />
             <!-- <van-field
                v-model="content"
                label="内容"
                placeholder="请输入内容"
                :rules="[{ required: true}]"
            >
            <template #input>
                <div class="mavon-editor" id="content">
                    <mavon-editor :subfield="false" v-model="notifyInfo.content" :toolbars="toolbars"/>
                </div>
            </template>
            </van-field> -->
            <div class="form-bottom">
                <van-button round block type="info" native-type="submit" v-show="isAdmin">发布</van-button>
            </div>
        </van-form>
         <van-overlay :show="showLoading" z-index="9999">
            <div class="check-img-container">
                <div :class="{'check-img': true, 'checking': checkClass == 'checking', 'check-success': checkClass == 'checkSuccess', 'check-fail': checkClass == 'checkFail' }"></div>
                <p>{{checkText}}</p>
            </div>
        </van-overlay>
    </div>
</template>

<script>
import axios from 'axios';
import {getToken, getUserType} from '@/utils/cookie'
import {addNewsNotice, modifyNewsNotice, checkSensitiveWords} from '@/api/notificationIssuance';
export default {
    data() {
        return {
            show: false,//是否显示重要程度
            actions: [{ name: '低', id: 0 }, { name: '中',id: 1 }, { name: '高',id: 2 }],
            switchChecked: false,//是否置顶
            notifyInfo: {
                title: '',
                context: '',
                levelName: '低',
                level: 0,
            },
            content: '',
            isAdd: true,//是否新增
            isAdmin: true,//是否是管理员
            toolbars: {
                bold: false, // 粗体
                italic: false, // 斜体
                save: true,
                imagelink: true, // 图片链接
                subfield: true, // 单双栏模式
                preview: true, // 预览
                // trash: true, // 清空
            },
            showLoading: false,//加载中
            checkText: '内容检测中',
            checkClass: 'checking',//检测成功
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.isAdmin = getUserType() == 1 ? true : false;
        });
    },
    methods: {
        onSubmit() {
            let promise = this.checkSensitiveWords();
            promise.then(() => {
                 if (this.notifyInfo.context) {
                    this.notifyInfo.top = this.switchChecked ? 1 : 0;
                    if (this.isAdd) {
                        addNewsNotice(this.notifyInfo).then(res => {
                            if (res.code == 200) {
                                this.$notify({type: 'success', message: '新增成功'});
                                this.$router.push({name: 'pressReleases'})
                            }
                        })
                    } else {
                        modifyNewsNotice(this.notifyInfo).then(res => {
                            if (res.code == 200) {
                                this.$notify({type: 'success', message: '编辑成功'});
                                this.$router.push({name: 'pressReleases'})
                            }
                        })
                    } 
                
                } else {
                    this.$notify({type: 'warning', message: '请输入内容'});
                }
            });
        },
        handleEditorImgAdd(pos, file) {
            let formData = new FormData();
            formData.append("file", file);
            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'token': getToken()
                }
            };
            axios.post('http://172.26.202.35:8100/nj/file/upload', formData, config).then(res => {
                if (res.data.code == 200) {
                    this.$refs.mavonEditor.$img2Url(pos, res.data.data[0]);
                }
            });
        },
        /** 删除图片 */
        imgDel(pos) {

        },
        /** 是否展示重要程度 */
        shouLevel() {
            this.show = true;
        },
        /** 选择程度 */
        selectLevel(val) {
            this.notifyInfo.levelName = val.name;
            this.notifyInfo.level = val.id;
        },
        onCancel() {
            this.show = false;
        },
         /** 检验敏感词 */
        checkSensitiveWords() {
            this.showLoading = true;
            this.checkClass = 'checking';
            this.checkText = '内容检测中';
            let promise = new Promise((resolve, reject) => {
                let obj = {
                    value: this.notifyInfo.title + ';'+this.notifyInfo.context
                }
                checkSensitiveWords(obj).then(res => {
                    if (res.code == 200) {
                        if (res.data.length > 0) {
                            this.checkClass = 'checkFail';
                            this.checkText = '内容不合格';
                            let str = '';
                            res.data.forEach(item => {
                                str += '【'+item+'】'
                            });
                            let text = `当前发布的园区相关信息中，含有敏感词汇${str}，故系统驳回您的发布请求，请对文字措辞进行修改后，再次提交园区信息，感谢您的配合！`
                            setTimeout(() => {
                                this.$dialog.confirm({
                                    title: '内容错误',
                                    message: text,
                                    confirmButtonText: '确定',
                                    cancelButtonText: '取消'
                                });
                                this.showLoading = false;
                            })
                            
                        } else {
                            this.checkClass = 'checkSuccess';
                            this.checkText = '检测通过';
                            setTimeout(() => {
                                this.showLoading = false;
                                resolve();
                            }, 1200);
                        }
                    } else {
                        this.$dialog.confirm({
                            title: '检测失败',
                            message: '敏感词检测失败，请重新提交！',
                            confirmButtonText: '确定',
                            cancelButtonText: '取消'
                        });
                        this.showLoading = false;
                    }
                })
            });
            return promise;
        }
    },
    beforeRouteEnter(to, from, next) {
        if (to.params.title) {
            next(vm => {
                vm.notifyInfo = to.params;
                vm.notifyInfo.context = to.params.context1;
                vm.switchChecked = to.params.top == 0 ? false : true;
                vm.notifyInfo.levelName = to.params.level == 0 ? '低' : to.params.level == 1 ? '中' : '高';
                if(getUserType() == 1){
                    document.title = '编辑新闻';
                }else{
                    document.title = '新闻详情';
                }
                vm.isAdd = false;
            })
        } else {
            next(vm => {
                vm.isAdd = true;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .add-notification-issuance {
        width: 347px;
        padding: 14px;
        height: 100%;
        background-color: #fff;
        .form-container {
            padding: 8px;
            .form-bottom {
                position: fixed;
                left: 12px;
                bottom: 10px;
                width: 346px;
            }
            .left-label {
                width: 60px;
                margin: 8px;
                margin-left: 14px;
                margin-top: 20px;
                font-size: 16px;
                font-weight: 500;
                color: #000;
                text-align: left;
            }
        }
        .title-label {
            font-size: 16px;
            font-weight: 500;
            color: #000;
            margin-top: 1px;
            .van-cell__value  {
                display: none;
            }
        }
        .title-input  { 
            height: 50px;
            box-shadow: 0px 0px 8px 0px rgba(205,205,205,0.5);
            border-radius: 4px;
            background: #fff;
        }
    }
</style>
<style lang="scss">
.add-notification-issuance {
    .van-field__label {
        width: 60px;
    }
    .op-icon.dropdown-wrapper.dropdown .popup-dropdown {
        left: -11px !important;
    }
    .van-button--round {
        border-radius: 4px;
        border: 1px solid #4499FF;
        background-color: #4499FF;
    }
    .van-cell::after {
        border-bottom: 0;
    }
    .title-label {
        .van-cell__value  {
            display: none;
        }
    }
    .van-cell::after {
        border: 0;
    }
    .check-img-container {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 128px;
        height: 128px;
        border-radius: 4px;
        transform: translate(-50%, -50%);
        background: #fff;
        .check-img {
            width: 72px;
            height: 72px;
            margin: 0 auto;
            margin-top: 14px;
            margin-bottom: 8px;
        }
        .checking {
            background: url(../../assets/images/news/checkIng.gif) no-repeat;
            background-size: contain;
        }
        .check-success {
            background: url(../../assets/images/news/checkSuccess.gif) no-repeat;
            background-size: contain;
        }
        .check-fail {
            background: url(../../assets/images/news/checkFail.gif) no-repeat;
            background-size: contain;
            &+p {
                color: #FF4C4C;
            }
        }
        p {
            font-size: 14px;
            text-align: center;
            color: #31BF97;
        }
        
    }
}
</style>