// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/news/checkIng.gif");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/news/checkSuccess.gif");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/images/news/checkFail.gif");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".add-notification-issuance .van-field__label{width:1.6rem}.add-notification-issuance .op-icon.dropdown-wrapper.dropdown .popup-dropdown{left:-.29333rem!important}.add-notification-issuance .van-button--round{border-radius:.10667rem;border:.02667rem solid #49f;background-color:#49f}.add-notification-issuance .van-cell:after{border-bottom:0}.add-notification-issuance .title-label .van-cell__value{display:none}.add-notification-issuance .van-cell:after{border:0}.add-notification-issuance .check-img-container{position:absolute;top:50%;left:50%;width:3.41333rem;height:3.41333rem;border-radius:.10667rem;transform:translate(-50%,-50%);background:#fff}.add-notification-issuance .check-img-container .check-img{width:1.92rem;height:1.92rem;margin:0 auto;margin-top:.37333rem;margin-bottom:.21333rem}.add-notification-issuance .check-img-container .checking{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:contain}.add-notification-issuance .check-img-container .check-success{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:contain}.add-notification-issuance .check-img-container .check-fail{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;background-size:contain}.add-notification-issuance .check-img-container .check-fail+p{color:#ff4c4c}.add-notification-issuance .check-img-container p{font-size:.37333rem;text-align:center;color:#31bf97}", ""]);
// Exports
module.exports = exports;
