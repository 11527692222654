import request from '@/utils/request';
/** 列表 */
export function listPageNewsNotice(data) {
    return request({
        url: '/newsNotice/listPageNewsNotice',//请求路径
        method: 'get',
        params: data
    });
}
/** 新增通知 */
export function addNewsNotice(data) {
    return request({
        url: '/newsNotice/addNewsNotice',//请求路径
        method: 'post',
        data
    });
}
/** 编辑通知 */
export function modifyNewsNotice(data) {
    return request({
        url: '/newsNotice/modifyNewsNotice',//请求路径
        method: 'post',
        data
    });
}
/** 删除 */
export function deleteNewsNotice(data) {
    return request({
        url: '/newsNotice/deleteNewsNotice',//请求路径
        method: 'post',
        data
    });
}
/* 获取用户信息 */
export function getCurrentUser(data) {
    return request({
        url: '/rbac/user/getCurrentUser',
        method: 'get',
        params: data
    })
}
//检验敏感词信息
export function checkSensitiveWords(data) {
    return request({
        url: '/sensitiveWords/checkWords',//请求路径
        method: 'post',
        data
    });
}